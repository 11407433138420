

























































































import {Component, Vue} from 'vue-property-decorator';
import {CreateUserDTO, OutputOrganizationDTO} from '@/api/api';
import {mapGetters} from "vuex";
import ApiRole, {formatApiRole} from "../../../types/ApiRole";
import Loading from "@/components/Loading.vue";

@Component({
  components: {Loading},
  computed: mapGetters({
    superAdminGranted: 'api/superAdminGranted'
  })
})
export default class UsersCreate extends Vue {
  public superAdminGranted!: boolean;

  public organizations: OutputOrganizationDTO[]|null = null;

  public valid = false;
  public formData = {
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    role: ApiRole.ROLE_LOCATION_ADMIN,
    organizationId: null,
    password1: '',
    password2: ''
  }

  public async mounted() {
    this.organizations = await this.$store.dispatch('organizations/getAll')
    this.reset();
  }

  public reset() {
    this.formData = {
      username: '',
      firstName: '',
      lastName: '',
      email: '',
      role: ApiRole.ROLE_LOCATION_ADMIN,
      organizationId: null,
      password1: '',
      password2: ''
    }
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const dto: CreateUserDTO = {
        username: this.formData.username,
        first_name: this.formData.firstName,
        last_name: this.formData.lastName,
        email: this.formData.email,
        password: this.formData.password1,
        organization_id: this.formData.organizationId,
        roles: [this.formData.role]
      }
      await this.$store.dispatch('users/create', {data: dto});
      await this.$router.push('/main/admin/users');
    }
  }

  get roleItems() {
    const baseRoles = [
      {
        value: ApiRole.ROLE_TECHNICAL_SUPPORT,
        text: formatApiRole(ApiRole.ROLE_TECHNICAL_SUPPORT)
      },
      {
        value: ApiRole.ROLE_ORGANIZATION_ADMIN,
        text: formatApiRole(ApiRole.ROLE_ORGANIZATION_ADMIN)
      },
      {
        value: ApiRole.ROLE_LOCATION_ADMIN,
        text: formatApiRole(ApiRole.ROLE_LOCATION_ADMIN)
      }
    ]

    if (this.superAdminGranted) {
      return [
        {
          value: ApiRole.ROLE_SUPER_ADMIN,
          text: formatApiRole(ApiRole.ROLE_SUPER_ADMIN)
        },
        ...baseRoles
      ]
    }

    return baseRoles
  }

  get organizationItems() {
    if (this.organizations) {
      return this.organizations.map(org => {
        return {
          value: org.id,
          text: org.name
        }
      })
    } else {
      return [];
    }
  }

  get organizationSelectRequired() {
    return this.formData.role === ApiRole.ROLE_LOCATION_ADMIN.valueOf() || this.formData.role === ApiRole.ROLE_ORGANIZATION_ADMIN.valueOf()
  }

  get organizationsLoading() {
    return this.organizations === null;
  }
}
